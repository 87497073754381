import axios from "../Schemas/API";
import React, { useEffect, useState } from "react";
import Prev from "../Images/previous.png";
import Next from "../Images/next.png";
import Serch from "../Images/search.png";
import Eye from "../Images/eye.png";
import Delete from "../Images/delete.png";
import { useNavigate } from "react-router";
import { Modal } from "react-bootstrap";
const EmailUser = () => {
  const [user, setUser] = useState([]);
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [popu, setPopu] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [serch, setSerch] = useState("");
  const navigate = useNavigate();
  const MyToken = localStorage.getItem("TOKEN");
  const handlepage = (newpage) => {
    const nextPage = Math.max(newpage, 1);

    setCurrentpage(nextPage);
  };
  const handleuser = (item) => {
    navigate("/business-user-deatil", {
      state: {
        item,
      },
    });
  };

  const handleDelete = async () => {
    try {
      const res = await axios.post(
        "/post/delete",
        {
          id: popu?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      getUsers();
      console.log(res, "delete ki api now");
      setShow(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getUsers = async () => {
    try {
      const res = await axios.post(
        "/user/all-business-user",
        {
          type: "1",
          keyword: serch,
          page: currentpage.toString(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setTotalPages(res.data.totalPages);
      setUser(res.data.data);

      console.log(res, "all data fied");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUsers(currentpage, "1");
  }, [currentpage, serch]);
  const handleShow = (item) => {
    setPopu(item);
    setShow(true);
  };
  const Deletepopup = (item) => {
    return (
      <Modal show={show} onHide={handleClose} backdrop="static" size="md">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <span className="span-delete-heres">View Post Detail</span>
        <hr />
        <Modal.Body>
          <div className="table-responsive-add">
            <div className="flex-delete-popup-refunds">
              <img src={popu.image} alt="" className="Two-img-popu-heres" />
              <div className="name-post-span" style={{ marginTop: "0.8rem" }}>
                <span>{popu.firstname}</span>
                <span>{popu.lastname}</span>
              </div>
              <hr className="def-border-post" />
              <span className="span-delete-sure-you">
                Are you sure you want to delete this post?
              </span>
              <div className="post-want-flex-yes">
                <button
                  style={{ background: "red", color: "#fff" }}
                  onClick={() => setShow(false)}
                >
                  No
                </button>
                <button
                  style={{
                    background: "#fff",
                    color: "red",
                    border: "1px solid red",
                  }}
                  onClick={() => handleDelete(popu?.id)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <>
      <Deletepopup show={show} handleClose={() => setShow(false)} />
      <div className="new-add-important-flex">
        <div className="Main-Content-class">
          <div className="page-content">
            <div
              className="Category-product"
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="three-ones">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ color: "#0a58ca" }}
                >
                  <path
                    fill="currentColor"
                    d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                  />
                </svg>
                <a
                  style={{
                    color: "#008cff",
                    fontWeight: "500",
                    fontSize: "16px",
                    textDecoration: "none",
                  }}
                  href=""
                >
                  Email User Listing
                </a>
              </div>
            </div>
            <hr className="hr-tag-class" />
            <div className="table-responsive-add">
              <div className="serch-beetwin-two">
                <div
                  style={{
                    gap: "0.4rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "280px",
                    border: "1px solid grey",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <img
                    src={Serch}
                    alt=""
                    style={{ width: "24px", height: "24px" }}
                  />
                  {/* <label htmlFor="Search">Search:</label> */}
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    id="Search"
                    value={serch}
                    style={{
                      borderRadius: "4px",
                      outline: "none",
                      border: "1px solid #ced4da",
                      width: "280px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                    onChange={(e) => setSerch(e.target.value)}
                  />
                </div>
              </div>
              <div className="easy-add-scroll-new">
                <div className="card" style={{ marginTop: "2rem" }}>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        id="example"
                        className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Number</th>
                            <th>Gender</th>
                            <th>Company Name</th>
                            <th>Position Company</th>
                            <th>Business Category</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {user && user.length > 0 ? (
                            user.map((item, index) => {
                              const serialNumber = index + 1;
                              return (
                                <>
                                  <tr>
                                    <td>{serialNumber}</td>
                                    <td>
                                      <img
                                        src={item.image}
                                        alt="null_img"
                                        style={{
                                          width: "auto",
                                          height: "50px",
                                        }}
                                      />
                                    </td>
                                    <div
                                      style={{ display: "flex", gap: "0.5rem" }}
                                    >
                                      <div className="responsive-design">
                                        {item.firstname}
                                      </div>
                                      {item.lastname}
                                    </div>
                                    <td>{item.email}</td>
                                    <div
                                      style={{ display: "flex", gap: "0.5rem" }}
                                    >
                                      <div className="responsive-design">
                                        {item.country_code}
                                      </div>
                                      {item.phonenumber}
                                    </div>
                                    <td>{item.gender}</td>

                                    <td>{item.company_name}</td>
                                    <td>{item.position_company}</td>
                                    <td>{item.business_category}</td>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "1rem",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: "1rem",
                                          // height: "80px",
                                        }}
                                      >
                                        <button
                                          className="delete-buttons-red"
                                          style={{
                                            border: "1px solid #15ca20",
                                          }}
                                          onClick={() =>
                                            handleuser({
                                              id: item?.id,
                                            })
                                          }
                                        >
                                          <img
                                            src={Eye}
                                            alt="Delete"
                                            className="delete-btn-red"
                                          />
                                        </button>
                                        <button
                                          className="delete-buttons-red"
                                          onClick={() => {
                                            handleShow(item);
                                          }}
                                        >
                                          <img
                                            src={Delete}
                                            alt="Delete"
                                            className="delete-btn-red"
                                          />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="8">Data Not Found</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Sr No.</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Number</th>
                            <th>Gender</th>
                            <th>Image</th>
                            <th>Company Name</th>
                            <th>Position Company</th>
                            <th>Business Category</th>
                            <th>Action</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="Page-button-click">
                  <div onClick={() => handlepage(currentpage - 1)}>
                    <img src={Prev} alt="" className="both-Next-Prev" />
                  </div>
                  <span style={{ fontSize: "20px" }}>{currentpage}</span>
                  <div
                    onClick={() =>
                      handlepage(
                        totalPages == currentpage
                          ? currentpage
                          : currentpage + 1
                      )
                    }
                  >
                    <img
                      src={Next}
                      alt=""
                      style={{
                        opacity: totalPages == currentpage ? "0.5" : "1",
                      }}
                      className="both-Next-Prev"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailUser;
