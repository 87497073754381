import React, { useEffect, useState } from "react";
import "../Post/Post.css";
import axios from "../Schemas/API";
import Prev from "../Images/previous.png";
import Next from "../Images/next.png";
import Serch from "../Images/search.png";
import Delete from "../Images/delete.png";
import Eye from "../Images/eye.png";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
const Post = () => {
  const [show, setShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [popu, setPopu] = useState([]);
  const handleShow = (item) => {
    setPopu(item);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  const [post, setPost] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [totalpages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const MyToken = localStorage.getItem("TOKEN");
  // get-all Post Api Here.........................
  const getAllPost = async () => {
    try {
      const res = await axios.post(
        "/post/get-all",
        {
          page: currentpage.toString(),
          keyword: search,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setPost(res.data.data);
      // setTotalPages(res.data.totalPages);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllPost(currentpage, "1");
  }, [currentpage, search]);
  const handlepage = (newpage) => {
    const nextPage = Math.max(newpage, 1);
    setCurrentpage(nextPage);
  };

  const navigate = useNavigate();
  //   POST DELETE API FUNCTION.................................
  const PostDelete = async () => {
    try {
      const res = await axios.post(
        "/post/delete",
        {
          id: popu?.id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      getAllPost();
    } catch (error) {
      console.log(error);
    }
  };

  const handleData = (items) => {
    const item = { data: items, page: "dashboard" };
    navigate("/Postview", {
      state: {
        item,
      },
    });
  };

  const Deletepopup = (item) => {
    return (
      <Modal show={show} onHide={handleClose} backdrop="static" size="md">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <span className="span-delete-heres">View Post Detail</span>
        <hr />
        <Modal.Body>
          <div className="table-responsive-add">
            <div className="flex-delete-popup-refunds">
              <img src={popu.gallery} alt="" className="Two-img-popu-heres" />
              <div className="name-post-span" style={{ marginTop: "0.8rem" }}>
                <span>{popu?.created_by?.firstname}</span>
                <span>{popu?.created_by?.lastname}</span>
              </div>
              <hr className="def-border-post" />
              <span className="span-delete-sure-you">
                Are you sure you want to delete this post?
              </span>
              <div className="post-want-flex-yes">
                <button
                  style={{ background: "red", color: "#fff" }}
                  onClick={() => setShow(false)}
                >
                  No
                </button>
                <button
                  style={{
                    background: "#fff",
                    color: "red",
                    border: "1px solid red",
                  }}
                  onClick={() => PostDelete(item?.id)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  // TrunCateMessageLogic Here..................................................................

  const truncateMessage = (Message, maxWords) => {
    const words = Message.split("");
    if (words.length > maxWords) {
      return words.slice(0, maxWords).join("") + "...";
    } else {
      return Message;
    }
  };
  return (
    <>
      <Deletepopup show={show} handleClose={() => setShow(false)} />
      <div className="new-add-important-flex">
        <div className="Main-Content-class" style={{ alignItems: "flex-end" }}>
          <div className="page-content" style={{ marginTop: "2rem" }}>
            <div
              className="Category-product"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="three-ones">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ color: "#0a58ca" }}
                >
                  <path
                    fill="currentColor"
                    d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                  />
                </svg>
                <a
                  style={{
                    color: "#008cff",
                    fontWeight: "500",
                    fontSize: "16px",
                    textDecoration: "none",
                  }}
                  href=""
                >
                  Post
                </a>
                <span>Category</span>
              </div>
            </div>
            <span className="list-of-products"> POST CATEGORY LIST</span>
            <hr className="hr-tag-class" />
            <div className="table-responsive-add">
              <div className="serch-beetwin-two">
                <div
                  style={{
                    gap: "0.4rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "280px",
                    border: "1px solid grey",
                    borderRadius: "4px",
                    padding: "4px",
                  }}
                >
                  <img
                    src={Serch}
                    alt=""
                    style={{ width: "24px", height: "24px" }}
                  />
                  <input
                    className="form-control form-control-sm"
                    type="Search"
                    style={{
                      borderRadius: "4px",
                      outline: "none",
                      border: "1px solid #ced4da",
                      width: "280px",
                      border: "none",
                      borderRadius: "4px",
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>

              <div className="easy-add-scroll-new">
                <table style={{ marginTop: "3rem" }}>
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Like</th>
                      <th>Comment</th>
                      <th>Repost</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {post && post.length > 0 ? (
                      post.map((item, index) => {
                        const serialNumber = index + 1;
                        return (
                          <>
                            <tr>
                              <td className="add-bold-in-font">
                                {serialNumber}
                              </td>
                              <td>
                                <img
                                  src={item.gallery}
                                  alt=""
                                  style={{
                                    width: "60px",
                                    height: "60px",
                                    borderRadius: "10px",
                                    border: "1px solid #e6e6e6",
                                  }}
                                />
                              </td>
                              <td>
                                <div
                                  className="add-bold-in-font"
                                  style={{
                                    display: "flex",
                                    gap: "0.5rem",

                                    textAlign: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    className="responsive-design"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {item.created_by.firstname}
                                  </div>
                                  <div
                                    className="responsive-design"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {item.created_by.lastname}
                                  </div>
                                </div>
                              </td>
                              <td className="add-bold-in-font">
                                {truncateMessage(item.desc, 30)}
                              </td>
                              <td className="add-bold-in-font">
                                {item.total_like}
                              </td>
                              <td className="add-bold-in-font">
                                {item.total_comment}
                              </td>
                              <td className="add-bold-in-font">
                                {item.total_repost}
                              </td>
                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "1rem",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  <button
                                    className="delete-buttons-red"
                                    style={{ border: "1px solid #15ca20" }}
                                    onClick={() =>
                                      handleData({
                                        userId: item.created_by.id,
                                        Postid: item.id,
                                      })
                                    }
                                  >
                                    <img
                                      src={Eye}
                                      alt="Delete"
                                      className="delete-btn-red"
                                    />
                                  </button>
                                  <button
                                    className="delete-buttons-red"
                                    onClick={() => handleShow(item)}
                                  >
                                    <img
                                      src={Delete}
                                      alt="Delete"
                                      className="delete-btn-red"
                                    />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <span>Data Not Found</span>
                    )}
                  </tbody>
                  <tfoot>
                    <tr>
                      <th>Sr No</th>
                      <th>Name</th>
                      <th>Image</th>
                      <th>Description</th>
                      <th>Like</th>
                      <th>Comment</th>
                      <th>Repost</th>
                      <th>Action</th>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
          <div className="Page-button-click" style={{ marginTop: "0" }}>
            <div onClick={() => handlepage(currentpage - 1)}>
              <img src={Prev} alt="" className="both-Next-Prev" />
            </div>
            <span style={{ fontSize: "20px" }}>{currentpage}</span>
            <div
              onClick={() =>
                handlepage(
                  totalpages == currentpage ? currentpage : currentpage + 1
                )
              }
            >
              <img
                src={Next}
                style={{ opacity: totalpages == currentpage ? "0.5" : "1" }}
                alt=""
                className="both-Next-Prev"
              />
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Post;
