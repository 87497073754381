import axios from "../../Schemas/API";
import React, { useEffect, useState } from "react";
import Prev from "../../Images/previous.png";
import Next from "../../Images/next.png";
import { useNavigate } from "react-router";
import { MdEdit } from "react-icons/md";
import Delete from "../../Images/delete.png";
import { Modal } from "react-bootstrap";

const Blog = () => {
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const [popu, setPopu] = useState([]);
  const [user, setUser] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [serch, setSerch] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const MyToken = localStorage.getItem("TOKEN");
  const getInvester = async () => {
    try {
      const res = await axios.post(
        "blog/get",
        {
          keyword: serch,
          page: currentpage.toString(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setUser(res.data.data);
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    getInvester(currentpage, "1");
  }, [currentpage, serch]);

  const handlepage = (newpage) => {
    const nextPage = Math.max(newpage, 1);
    setCurrentpage(nextPage);
  };
  const navigate = useNavigate();
  const handleData = (item) => {
    setPopu(item);
    setShow(true);
  };
  const PostDelete = async () => {
    try {
      const res = await axios.post(
        "blog/delete",
        {
          id: popu?.id.toString(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MyToken}`,
          },
        }
      );
      setShow(false);
      getInvester();
      console.log(res, "delete ho gya");
    } catch (error) {
      console.log(error);
    }
  };
  const handleEditInvester = (item) => {
    navigate("/EditBlog", {
      state: {
        item,
      },
    });
  };
  const Deletepopup = () => {
    return (
      <Modal show={show} onHide={handleClose} backdrop="static" size="md">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <span className="span-delete-heres">View Blog Detail</span>
        <hr />
        <Modal.Body>
          <div className="table-responsive-add">
            <div className="flex-delete-popup-refunds">
              <img src={popu?.image} alt="" className="Two-img-popu-heres" />
              <div className="name-post-span" style={{ marginTop: "0.8rem" }}>
                <span>{popu?.created_by?.firstname}</span>
                <span>{popu?.created_by?.lastname}</span>
              </div>
              <hr className="def-border-post" />
              <span className="span-delete-sure-you">
                Are you sure you want to delete this Blog?
              </span>
              <div className="post-want-flex-yes">
                <button
                  style={{ background: "red", color: "#fff" }}
                  onClick={() => setShow(false)}
                >
                  No
                </button>
                <button
                  style={{
                    background: "#fff",
                    color: "red",
                    border: "1px solid red",
                  }}
                  onClick={() => PostDelete(popu?.id)}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  return (
    <>
      <Deletepopup show={show} handleClose={() => setShow(false)} />
      <div className="new-add-important-flex">
        {/* <Sidebar /> */}
        <div className="Main-Content-class">
          <div className="page-content">
            <div
              className="Category-product"
              style={{
                marginTop: "2rem",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="three-ones">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  style={{ color: "#0a58ca" }}
                >
                  <path
                    fill="currentColor"
                    d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                  />
                </svg>
                <a
                  style={{
                    color: "#008cff",
                    fontWeight: "500",
                    fontSize: "16px",
                    textDecoration: "none",
                  }}
                  href=""
                >
                  Blog
                </a>
              </div>
            </div>
            <hr className="hr-tag-class" />
            <div className="table-responsive-add">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: "2rem",
                }}
              >
                <div className="serch-beetwin-two">
                  <div
                    style={{
                      gap: "0.4rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <label htmlFor="Search">Search:</label>
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      id="Search"
                      value={serch}
                      onChange={(e) => setSerch(e.target.value)}
                    />
                  </div>
                </div>
                <button
                  onClick={() => navigate("/AddBlog")}
                  style={{
                    width: "150px",
                    height: "40px",
                    background: "red",
                    border: "none",
                    borderRadius: "5px",
                    color: "#ffff",
                  }}
                >
                  Add Blog
                </button>
              </div>
              <div className="easy-add-scroll-new">
                <div className="card" style={{ marginTop: "2rem" }}>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        id="example"
                        className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {user && user.length > 0 ? (
                            user.map((item, index) => {
                              const serialNumber = index + 1;
                              return (
                                <>
                                  <tr>
                                    <td>{serialNumber}</td>
                                    <td
                                      style={{ display: "flex", gap: "0.5rem" }}
                                    >
                                      <div className="responsive-design">
                                        <img
                                          src={item.image}
                                          alt=""
                                          className="img-popu-heres"
                                          style={{
                                            width: "60px",
                                            height: "60px",
                                            borderRadius: "10px",
                                          }}
                                        />
                                      </div>
                                    </td>

                                    <td className="responsive-design">
                                      {item.title_english}
                                    </td>

                                    {/* <td className="responsive-design">
                                      {item.description_english}
                                    </td> */}
                                    <td className="responsive-design">
                                      {item.description_english.length > 50
                                        ? `${item.description_english.slice(
                                            0,
                                            50
                                          )}...`
                                        : item.description_english}
                                    </td>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: "1rem",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          marginTop: "1rem",
                                        }}
                                      >
                                        <button
                                          className="delete-buttons-red"
                                          style={{
                                            border: "1px solid red",
                                          }}
                                          onClick={() => handleData(item)}
                                          //   onClick={() =>
                                          //     handleData({
                                          //       userId: item.created_by.id,
                                          //       Postid: item.id,
                                          //     })
                                          //   }
                                        >
                                          <img
                                            src={Delete}
                                            alt="Delete"
                                            className="delete-btn-red"
                                          />
                                        </button>
                                        <button
                                          className="delete-buttons-red"
                                          onClick={() =>
                                            handleEditInvester(item)
                                          }
                                          style={{
                                            border: "1px solid goldenrod",
                                          }}
                                        >
                                          <MdEdit
                                            className="delete-btn-red"
                                            style={{
                                              color: "goldenrod",
                                              marginTop: "0.5rem",
                                            }}
                                          />
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan="8">Data Not Found</td>
                            </tr>
                          )}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>Sr No.</th>
                            <th>Image</th>
                            <th>Title</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="Page-button-click">
                  <div onClick={() => handlepage(currentpage - 1)}>
                    <img src={Prev} alt="" className="both-Next-Prev" />
                  </div>
                  <span style={{ fontSize: "20px" }}>{currentpage}</span>
                  <div
                    onClick={() =>
                      handlepage(
                        totalPages == currentpage
                          ? currentpage
                          : currentpage + 1
                      )
                    }
                  >
                    <img
                      src={Next}
                      alt=""
                      style={{
                        opacity: totalPages == currentpage ? "0.5" : "1",
                      }}
                      className="both-Next-Prev"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blog;

// description_turkish: description1,
//           description_french: description2,
//           description_portuguese: description3,
//           description_swahili: description4,
//           description_spanish: description5,
//           description_english: description6,
