import React, { useEffect, useRef, useState } from "react";
import "../Invester/InvesterEdit.css";
import axios from "axios";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

const AddBlog = () => {
  const navigate = useNavigate();
  const editor = useRef(null);

  //   const [countryId, setCountryId] = useState("");
  const [title, setTitle] = useState("");
  const [frenc, setFrenc] = useState("");
  const [portuguese, setPortuguese] = useState("");
  const [swahili, setSwahili] = useState("");
  const [spanish, setSpanish] = useState("");
  const [english, setEnglish] = useState("");
  const [description1, setDescription1] = useState("");
  const [description2, setDescription2] = useState("");
  const [description3, setDescription3] = useState("");
  const [description4, setDescription4] = useState("");
  const [description5, setDescription5] = useState("");
  const [description6, setDescription6] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const route = useLocation();
  const [Detail, setDetail] = useState([]);
  // getCountry API Function...............................................
  const MyToken = localStorage.getItem("TOKEN");

  //   const handleCountryChange = (e) => {
  //     setCountryId(e.target.value);
  //   };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("image", file);
    uploadimg(formData);
  };
  const uploadimg = async (formData) => {
    try {
      const res = await axios.post(
        "https://api.turkafrica.market/admin/v1/common/uploadImage",
        formData,
        {
          headers: {
            Authorization: `Bearer ${MyToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setPreviewUrl(res.data.data.image_url);
    } catch (error) {
      console.log(error);
    }
  };
  const initialvalues = {
    title: "",
    frenc: "",
    swahili: "",
    portuguese: "",
    spanish: "",
    english: "",
    description1: "",
    description2: "",
    description3: "",
    description4: "",
    description5: "",
    description6: "",
    image: "",
  };

  const onSubmit = async () => {
    try {
      const res = await axios.post(
        "https://api.turkafrica.market/admin/v1/blog/add",
        {
          title_turkish: title,
          title_french: frenc,
          title_portuguese: portuguese,
          title_swahili: swahili,
          title_spanish: spanish,
          title_english: english,
          description_turkish: description1,
          description_french: description2,
          description_portuguese: description3,
          description_swahili: description4,
          description_spanish: description5,
          description_english: description6,
          image: previewUrl,
        },
        {
          headers: {
            Authorization: `Bearer ${MyToken}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(res, "editediteditedit");
      navigate("/Blog");
      if (previewUrl === "") {
        toast.error("please Select the image");
      }
      toast.success(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(res.data.message);
    }
  };
  const { handleSubmit } = useFormik({
    initialValues: initialvalues,
    onSubmit,
  });

  //   const getInvesterEdit = async () => {
  //     try {
  //       const res = await axios.post(
  //         "https://api.turkafrica.market/admin/v1/resources/get-insvester-detail",
  //         {
  //           id: InvesterData.id,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${MyToken}`,
  //             "Content-Type": "application/json",
  //           },
  //         }
  //       );
  //       console.log(res, "detail");
  //       setDetail(res.data.data);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   useEffect(() => {
  //     getInvesterEdit();
  //   }, []);

  //   useEffect(() => {
  //     if (Detail) {
  //       setTitle(Detail.name);
  //       setEmail(Detail.email);
  //       setNumber(Detail.contact_number);
  //       setCountryId(Detail.countryId);
  //       setCity(Detail.City);
  //       setState(Detail.State);
  //       setLocation(Detail.location);
  //       setPreviewUrl(Detail.image);
  //     }
  //   }, [Detail]);
  return (
    <div className="new-add-important-flex">
      <div className="Main-Content-class" style={{ alignItems: "flex-start" }}>
        <div
          className="page-content"
          style={{ marginTop: "2rem", alignItems: "flex-end" }}
        >
          <div
            className="Category-product"
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="three-ones">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                style={{ color: "#0a58ca" }}
              >
                <path
                  fill="currentColor"
                  d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59l7-7l7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z"
                />
              </svg>
              <a
                style={{
                  color: "#008cff",
                  fontWeight: "500",
                  fontSize: "16px",
                  textDecoration: "none",
                }}
                href=""
              >
                AddBlog
              </a>
              <span>Blog</span>
            </div>
          </div>
          <hr className="hr-tag-class" />
        </div>
        <form className="" onSubmit={handleSubmit}>
          <div
            className="main-input-for-edits"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Title Turkish</span>
              <input
                type="text"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="form-control secounds-name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit" style={{ width: "700px" }}>
              <span className="span-name-invester">Description Turkish</span>
              <JoditEditor
                required
                ref={editor}
                type="type"
                value={description1}
                name="description1"
                style={{ height: "100px" }}
                onBlur={(newContent) => setDescription1(newContent)} // preferred to use only this option to update the content for performance reasons
                className="form-control secounds-name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Title Frenc</span>
              <input
                type="type"
                value={frenc}
                name="frenc"
                onChange={(e) => setFrenc(e.target.value)}
                className="form-control secounds-name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit" style={{ width: "700px" }}>
              <span className="span-name-invester">Description French</span>

              <JoditEditor
                required
                ref={editor}
                type="type"
                value={description2}
                name="description2"
                style={{ height: "100px" }}
                onBlur={(newContent) => setDescription2(newContent)} // preferred to use only this option to update the content for performance reasons
                className="form-control secounds-name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Title English</span>
              <input
                type="type"
                value={english}
                name="english"
                onChange={(e) => setEnglish(e.target.value)}
                className="form-control secounds-name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit" style={{ width: "700px" }}>
              <span className="span-name-invester">Description English</span>
              {/* <textarea
                type="type"
                value={description6}
                name="description6"
                style={{ height: "100px" }}
                onChange={(e) => setDescription6(e.target.value)}
                className="form-control name-input-same-css"
              /> */}
              <JoditEditor
                required
                ref={editor}
                type="type"
                value={description6}
                name="description6"
                style={{ height: "100px" }}
                onBlur={(newContent) => setDescription6(newContent)} // preferred to use only this option to update the content for performance reasons
                className="form-control secounds-name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Title Portuguese</span>
              <input
                type="type"
                value={portuguese}
                name="portuguese"
                onChange={(e) => setPortuguese(e.target.value)}
                className="form-control secounds-name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit" style={{ width: "700px" }}>
              <span className="span-name-invester">Description Portuguese</span>
              {/* <textarea
                type="type"
                value={description3}
                name="description3"
                style={{ height: "100px" }}
                onChange={(e) => setDescription3(e.target.value)}
                className="form-control name-input-same-css"
              /> */}
              <JoditEditor
                required
                ref={editor}
                type="type"
                value={description3}
                name="description3"
                style={{ height: "100px" }}
                onBlur={(newContent) => setDescription3(newContent)} // preferred to use only this option to update the content for performance reasons
                className="form-control secounds-name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Title Swahili</span>
              <input
                type="type"
                value={swahili}
                name="swahili"
                onChange={(e) => setSwahili(e.target.value)}
                className="form-control secounds-name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit" style={{ width: "700px" }}>
              <span className="span-name-invester">Description Swahili</span>
              {/* <textarea
                type="type"
                value={description4}
                name="description4"
                style={{ height: "100px" }}
                onChange={(e) => setDescription4(e.target.value)}
                className="form-control name-input-same-css"
              /> */}
              <JoditEditor
                required
                ref={editor}
                type="type"
                value={description4}
                name="description4"
                style={{ height: "100px" }}
                onBlur={(newContent) => setDescription4(newContent)} // preferred to use only this option to update the content for performance reasons
                className="form-control secounds-name-input-same-css"
              />
            </div>
            <div className="gap-name-input-edit">
              <span className="span-name-invester">Title Spanish</span>
              <input
                type="type"
                value={spanish}
                name="spanish"
                onChange={(e) => setSpanish(e.target.value)}
                className="form-control secounds-name-input-same-css"
              />
            </div>

            <div className="gap-name-input-edit" style={{ width: "700px" }}>
              <span className="span-name-invester">Description Spanish</span>
              {/* <textarea
                type="type"
                value={description5}
                name="description5"
                style={{ height: "100px" }}
                onChange={(e) => setDescription5(e.target.value)}
                className="form-control name-input-same-css"
              /> */}
              <JoditEditor
                required
                ref={editor}
                type="type"
                value={description5}
                name="description5"
                style={{ height: "100px" }}
                onBlur={(newContent) => setDescription5(newContent)} // preferred to use only this option to update the content for performance reasons
                className="form-control secounds-name-input-same-css"
              />
            </div>
          </div>
          <div
            className="create-select-img-main"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label className="form-label">Image </label>

            <input
              style={{ width: "30%" }}
              onChange={handleFileChange}
              type="file"
              accept="image/*"
              name="image"
              required
            />
            <div
              className={`image-container-secound--cate ${
                previewUrl ? "selected" : ""
              }`}
            >
              {previewUrl && (
                <img
                  src={previewUrl}
                  alt="Selected"
                  name="news_image"
                  className="select-img-here"
                />
              )}
            </div>
          </div>
          <button type="submit" className="update-btn-inexter">
            Add
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddBlog;
